import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import FileUploads from "./FileUploads";
import ProductionSheetUpload from "./ProductionSheetUpload";
import PostProductionSheetUpload from "./PostProductionSheetUpload";
import Table from "./Table";
import { Button } from "react-bootstrap";

const Header = ({setisLoggedIn = ()=>{}}) => {
  return (
    <>
      <Router>
        <Sidebar setisLoggedIn={setisLoggedIn} />
        <Switch>
          <Route path="/fileUploads">
            <FileUploads />
          </Route>
          <Route path="/productionsheetupload">
            <ProductionSheetUpload />
          </Route>
          <Route path="/postproductionsheetupload">
            <PostProductionSheetUpload />
          </Route>
          <Route path="/history">
            <Table />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
export default Header;
