import React, { useEffect, useMemo, useState } from "react";
import SelectionFilter from "./SelectionFilter";
import "../assets/css/DateFilterContainer.css";

const DateFilterContainer = ({
  filterDate,
  propLeft,
  propLetterSpacing,
  propLineHeight,
  options, // Array of options for the dropdown
  onSelectChange, // Callback function for handling select changes
  getFilterValue,
  getFilterValueStatus,
  getFilterValueFiletype,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const dateFilterStyle = useMemo(() => {
    return {
      letterSpacing: propLetterSpacing,
      lineHeight: propLineHeight,
    };
  }, [propLetterSpacing, propLineHeight]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if (onSelectChange) {
      onSelectChange(selectedValue);
    }
  };

  // Add a check for the existence of 'options' before mapping
  const renderedOptions = options
    ? options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))
    : null;

  return (
    <div className="selection-filter-parent" style={groupDivStyle}>
      {/* <SelectionFilter
        selectionFilterPosition="absolute"
        selectionFilterTop="26px"
        selectionFilterLeft="0px"
      /> */}
      <div className="date-filter">
        <div className="status-div">Status</div>
        <div className="select-select-div">
        <select onChange={(e) => getFilterValueStatus(e.target.value)} className="selectstyle">
          <option value="">Select</option>
          <option value="Failed">Failed</option>
          <option value="Success">Success</option>
        </select>
        </div>
      </div>
      <div className="date-filter-status">
      <div className="status-div">Uploaded By</div>
      <div className="select-select-div">
        <select onChange={(e) => getFilterValue(e.target.value)} className="selectstyle">
          <option value="">Select</option>
          <option value="Jacky">Jacky</option>
          {/* <option value="jacky">jacky</option>
          <option value="Subhasmita">Subhasmita</option> */}
        </select>
        </div>
      </div>
      <div className="date-filter-upload-file-type">
      <div className="status-div">File Type</div>
      <div className="select-select-div">
        <select onChange={(e) => getFilterValueFiletype(e.target.value)} className="selectstyle">
          <option value="">Select</option>
          <option value="1">Pre-Production</option>
          <option value="2">Production</option>
          <option value="3">Post-Production</option>
        </select>
        </div>
      </div>
    </div>
  );
};

export default DateFilterContainer;
