import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL_SP;

const Login = ({ setisLoggedIn = () => {} }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const onButtonClick = async(e) => {
    setEmailError("");
    setPasswordError("");

    if ("" === email) {
      setEmailError("Please enter your username");
      return;
    }
    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }
    e.preventDefault();

    try {
        const resp = await axios.post( apiUrl + "/v1/login/user", {
          username: email,
          password: password
        });
      
        if (resp?.data?.statusCode === '200') {
          console.log('login success', resp);
          localStorage.setItem("styli_fileUpload", JSON.stringify(resp.data.response))
          setisLoggedIn(true);
          // history.push('/header');
          history.push('/bulkupload');
          // Navigate("/header")
          // window.location.href = "/header";
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
  };

  return (
    <div className={"mainContainer"}>
      <div className={"titleContainer"}>
        <div>File Uploder</div>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={email}
          placeholder="Enter your username here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={password}
          type="password"
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={onButtonClick}
          value={"Log in"}
        />
      </div>
    </div>
  );
};

export default Login;
