import "../assets/css/BulkUpload.css";
import Head from "../components/page/Head";
import SiteBar from "./page/SiteBar";

const Dashboard = () => {
  return (
    <div className="home-bulk">
      <div className="home-child-bulk" />
      <Head />
      <SiteBar />
    </div>
  );
};

export default Dashboard;
