import "../assets/css/BulkUpload.css";
import Head from "../components/page/Head";
import SiteBar from "./page/SiteBar";
// import "../assets/css/FileUploadHistory.css"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const BulkUpload = () => {
  return (
    <div className="home-bulk">
      <div className="home-child-bulk" />
      <Head />
      <SiteBar />
      <div className="home-child1-bulk" />
      <Link to="/fileUploads" className="sample-bulk">
        Pre Production
      </Link>
      <div className="home-child2-bulk" />
      <Link to="/productionsheetupload" className="delivery-bulk">
        Production
      </Link>
      <div className="home-child3-bulk" />
      <Link to="/postproductionsheetupload" className="production-bulk">
        Post Production
      </Link>
      <b className="please-choose-the-bulk">
        Please choose the file type for upload
      </b>
      <img className="box-2-1-bulk" alt="" src="/box-2-1@2x.png" />
      <img className="scissors-1-1-bulk" alt="" src="/scissors-1-1@2x.png" />
      <img className="garment-1-icon-bulk" alt="" src="/garment-1@2x.png" />
    </div>
  );
};

export default BulkUpload;
