import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactSpreadsheetImport } from "subhasmitalmg-react-spreadsheet-import";
import { useHistory } from "react-router-dom";
const moment = require("moment");

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = "http://localhost:8080";
// const apiUrl = "https://sup-portal-service-qa-nrb4qa3iua-df.a.run.app";

// const Modal = ({ isModalOpen, onClose }) => {
//     return (
//         isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={onClose}>&times;</span>
//             <p>Submission successful! Your message or content here.</p>
//           </div>
//         </div>
//       )
//     );
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

export default function PostProductionSheetUpload() {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const OPTIONS = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];
  const OPTIONSVX = [
    { value: "DISPATCHED", label: "DISPATCHED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSLWI = [
    { value: "DELIVERED", label: "DELIVERED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSWAI = [
    { value: "PASS", label: "PASS" },
    { value: "CONDITIONAL PASS", label: "CONDITIONAL PASS" },
    { value: "FAIL", label: "FAIL" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSWR = [
    { value: "RETURN INITIATED", label: "RETURN INITIATED" },
    { value: "NOT REQUIRED", label: "NOT REQUIRED" },
  ];
  const OPTIONSWVR = [
    { value: "RETURNED", label: "RETURNED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSSCS = [
    { value: "CONSOLIDATED", label: "CONSOLIDATED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const [isOpen, setIsOpen] = useState(true);
  const autoMapSelectValues = true;
  const DownloadDbData = async () => {
    try {
      const response = await axios.post(
        apiUrl + "/fileuploader/postproductlist",
        // "http://localhost:8080/fileuploader/postproductlist",
        {}
      );
      const filelink = response.data.filelink;

      const link = document.createElement("a");
      link.href = filelink;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.error("Internal Server Error:", error.response.data);
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  };
  const onClose = () => {
    setIsOpen(false);
    // history.push("/header");
    history.push("/bulkupload");
  };
  const onDownload = (data, file) => {
    const allData = data.all;
    const invalidRows = [];
    allData.filter((item) => {
      if (item?.__errors) {
        invalidRows.push(item);
      }
    });

    // const numberOfInvalidRows = invalidRows.length;

    const output = [];
    const headerRow = [
      "DPO date",
      "Sourcing Country",
      "PO Number",
      "Option Id",
      "Vendor Manager",
      "Vendor X Factory Status",
      "Warehouse Inbound Iteration Number",
      "Local Warehouse Inbound Status",
      "Warehouse AQL Inspection Status",
      "Warehouse Return Status",
      "Warehouse - Vendor Return Status",
      "Shipment Consolidation Status",
      "Error Message",
    ];
    output.push(headerRow);

    invalidRows.forEach((item) => {
      const rowData = [
        item["dpo_date"],
        item["sourcing_country"],
        item["po_number"],
        item["option_id"],
        item["vendor_manager"],
        item["vendor_x_factory_status"],
        item["warehouse_inbound_iteration_number"],
        item["local_warehouse_inbound_status"],
        item["warehouse_aql_inspection_status"],
        item["warehouse_return_status"],
        item["warehouse_vendor_return_status"],
        item["shipment_consolidation_status"],
        item.__errors
          ? Object.values(item.__errors)
              .map((error) => error.message)
              .join(",")
          : "",
      ];
      output.push(rowData);
    });

    const XLSX = require("xlsx");
    const print = {
      invalidData: output,
    };
    const ws = XLSX.utils.aoa_to_sheet(print.invalidData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Invalid Data");

    XLSX.writeFile(wb, "download_errors_post.xlsx");
    setIsOpen(true);
  };
  const onSubmit = (data, file) => {
    try {
      console.log("data", data);
      const validdata = data.validData;
      const storedItem = localStorage.getItem('styli_fileUpload');
      const parsedItem = JSON.parse(storedItem);
      const firstname = parsedItem.user.firstname;
      // console.log('Firstname:', firstname);
      const username = firstname;

      axios.post(apiUrl + "/fileuploader/uploadpostproductdata", {
      // axios
      //   .post("http://localhost:8080/fileuploader/uploadpostproductdata", {
          validdata,
          username
        })
        .then((response) => {
          console.log("API Response:", response.data);
          console.log("Data Updated Successfully");
          // <Modal isModalOpen={isModalOpen} onClose={closeModal} />
        })
        .catch((error) => {
          console.error("An error occurred during API request:", error);
          // <Modal isModalOpen={isModalOpen} onClose={closeModal} />
        });

      // for (const item of validdata) {

      // 	const originalDateTimeString = item.dpo_date;
      // 	const formattedDateTimeString = moment(originalDateTimeString, 'MM/DD/YY H:mm').format('YYYY-MM-DD HH:mm:ss');

      // 	axios.post("http://localhost:8080/fileuploader/uploadpostproductdata", {
      // 	dpo_date: formattedDateTimeString,
      // 	sourcing_country: item.sourcing_country,
      // 	po_number: item.po_number,
      // 	option_id: item.option_id,
      // 	vendor_manager: item.vendor_manager,
      // 	vendor_x_factory_status: item.vendor_x_factory_status,
      // 	warehouse_inbound_iteration_number: item.warehouse_inbound_iteration_number,
      // 	local_warehouse_inbound_status: item.local_warehouse_inbound_status,
      // 	warehouse_aql_inspection_status: item.warehouse_aql_inspection_status,
      // 	warehouse_return_status: item.warehouse_return_status,
      // 	warehouse_vendor_return_status: item.warehouse_vendor_return_status,
      // 	shipment_consolidation_status: item.shipment_consolidation_status,
      // 	});
      // }
      console.log("Data Updated Successfully");
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setIsOpen(true);
  };
  var fields = [
    {
      label: "DPO date",
      key: "dpo_date",
      alternateMatches: ["DPO date"],
      fieldType: {
        type: "input",
      },
      example: "03/05/23",
      validations: [
        {
          rule: "required",
          errorMessage: "DPO date is required",
          level: "error",
        },
      ],
    },
    {
      label: "Sourcing Country",
      key: "sourcing_country",
      alternateMatches: ["Sourcing Country"],
      fieldType: {
        type: "input",
      },
      example: "India",
      validations: [
        {
          rule: "required",
          errorMessage: "Sourcing country is required",
          level: "error",
        },
      ],
    },
    {
      label: "PO Number",
      key: "po_number",
      alternateMatches: ["PO Number"],
      fieldType: {
        type: "input",
      },
      example: "70010067",
      validations: [
        {
          rule: "required",
          errorMessage: "PO Number is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "PO Number should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Option Id",
      key: "option_id",
      alternateMatches: ["Option Id"],
      fieldType: {
        type: "input",
      },
      example: "7005238902",
      validations: [
        {
          rule: "required",
          errorMessage: "Option Id is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "Option Id should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Vendor Manager",
      key: "vendor_manager",
      alternateMatches: ["Vendor Manager"],
      fieldType: {
        type: "input",
      },
      example: "RAJESH",
      validations: [
        {
          rule: "required",
          errorMessage: "Vendor Manager is required",
          level: "error",
        },
      ],
    },
    {
      label: "Vendor X Factory Status",
      key: "vendor_x_factory_status",
      alternateMatches: ["Vendor X Factory Status"],
      fieldType: {
        type: "select",
        options: OPTIONSVX,
      },
      example: "DISPATCHED",
      validations: [
        {
          rule: "required",
          errorMessage: "Vendor X Factory Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Warehouse Inbound Iteration Number",
      key: "warehouse_inbound_iteration_number",
      alternateMatches: ["Warehouse Inbound Iteration Number"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "DELIVERED",
      validations: [
        {
          rule: "required",
          errorMessage: "Warehouse Inbound Iteration Number is required",
          level: "error",
        },
      ],
    },
    {
      label: "Local Warehouse Inbound Status",
      key: "local_warehouse_inbound_status",
      alternateMatches: ["Local Warehouse Inbound Status"],
      fieldType: {
        type: "select",
        options: OPTIONSLWI,
      },
      example: "DELIVERED",
      validations: [
        {
          rule: "required",
          errorMessage: "Local Warehouse Inbound Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Warehouse AQL Inspection Status",
      key: "warehouse_aql_inspection_status",
      alternateMatches: ["Warehouse AQL Inspection Status"],
      fieldType: {
        type: "select",
        options: OPTIONSWAI,
      },
      example: "PASS",
      validations: [
        {
          rule: "required",
          errorMessage: "Warehouse AQL Inspection Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Warehouse Return Status",
      key: "warehouse_return_status",
      alternateMatches: ["Warehouse Return Status"],
      fieldType: {
        type: "select",
        options: OPTIONSWR,
      },
      example: "RETURN INITIATE",
      validations: [
        {
          rule: "required",
          errorMessage: "Warehouse Return Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Warehouse - Vendor Return Status",
      key: "warehouse_vendor_return_status",
      alternateMatches: ["Warehouse - Vendor Return Status"],
      fieldType: {
        type: "select",
        options: OPTIONSWVR,
      },
      example: "RETURNED",
      validations: [
        {
          rule: "required",
          errorMessage: "Warehouse - Vendor Return Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Shipment Consolidation Status",
      key: "shipment_consolidation_status",
      alternateMatches: ["Shipment Consolidation Status"],
      fieldType: {
        type: "select",
        options: OPTIONSSCS,
      },
      example: "CONSOLIDATED",
      validations: [
        {
          rule: "required",
          errorMessage: "Shipment Consolidation Status is required",
          level: "error",
        },
      ],
    },
  ];

  return (
    <div>
      {/* <Modal isModalOpen={isModalOpen} onClose={closeModal} /> */}
      <ReactSpreadsheetImport
        translations={{
          // alerts: {
          //     submitIncomplete :{
          //         bodyText : "There are still some rows that contain errors. A sheet with invalid/error data will download after clicking the 'submit' button, you can correct those and can upload it again and valid data will get uploaded.",
          //     }
          // },
          uploadStep: {
            title: "Post production file upload",
          },
          validationStep: {
            downloadButtonTitle: "Download Errors",
          },
        }}
        customTheme={{
          components: {
            UploadStep: {
              baseStyle: {
                dropzoneButton: {
                  bg: "green",
                },
              },
            },
          },
        }}
        rowHook={(data, addError) => {
          const local_warehouse_inbound_status =
            data.local_warehouse_inbound_status;
          const vendor_x_factory_status = data.vendor_x_factory_status;
          const warehouse_aql_inspection_status =
            data.warehouse_aql_inspection_status;
          const warehouse_vendor_return_status =
            data.warehouse_vendor_return_status;
          const warehouse_return_status = data.warehouse_return_status;
          const shipment_consolidation_status =
            data.shipment_consolidation_status;

          if (
            local_warehouse_inbound_status === "DELIVERED" &&
            vendor_x_factory_status != "DISPATCHED"
          ) {
            addError("local_warehouse_inbound_status", {
              message:
                "Local Warehouse Inbound Status can be DELIVERED if Vendor X Factory status is DISPATCHED",
              level: "error",
            });
          }
          if (
            local_warehouse_inbound_status === "PENDING" &&
            warehouse_aql_inspection_status != "PENDING"
          ) {
            addError("warehouse_aql_inspection_status", {
              message:
                "Warehouse AQL Inspection Status cannot be PASS/CONDITIONAL PASS/FAIL if Local Warehouse Inbound Status is PENDING",
              level: "error",
            });
          }
          if (
            warehouse_return_status === "RETURN INITIATED" &&
            warehouse_aql_inspection_status != "FAIL"
          ) {
            addError("warehouse_return_status", {
              message:
                "Warehouse RETURN Status can have value RETURN INITIATED only if warehouse AQL Inspection Status is FAIL",
              level: "error",
            });
          }
          if (
            warehouse_vendor_return_status === "RETURNED" &&
            warehouse_return_status != "RETURN INITIATED"
          ) {
            addError("warehouse_vendor_return_status", {
              message:
                "Warehouse - Vendor Return Status can only be RETURNED if Warehouse Return Status is RETURN INITIATED",
              level: "error",
            });
          }
          if (
            shipment_consolidation_status === "CONSOLIDATED" &&
            (warehouse_aql_inspection_status === "PENDING" ||
              warehouse_aql_inspection_status === "FAIL")
          ) {
            addError("shipment_consolidation_status", {
              message:
                "Shipment Consolidation Status can only be CONSOLIDATED if Warehouse AQL Inspection Status is PASS/CONDITIONAL PASS",
              level: "error",
            });
          }
          return data;
        }}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        onDownload={onDownload}
        DownloadDbData={DownloadDbData}
        autoMapSelectValues={autoMapSelectValues}
        fields={fields}
      />
    </div>
  );
}
