import "../assets/css/UserLogin.css";
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL_SP;
import loginBkground from "../assets/images/loginBkground.png";
import eyeIcon from "../assets/images/Group.svg"; // Add the path to your eye icon image

const UserLogin = ({ setisLoggedIn = () => {} }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onButtonClick = async (e) => {
    setEmailError("");
    setPasswordError("");

    if ("" === email) {
      setEmailError("Please enter your username");
      return;
    }
    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }
    e.preventDefault();

    try {
      const resp = await axios.post(apiUrl + "/v1/login/user", {
        username: email,
        password: password,
      });

      if (resp?.data?.statusCode === "200") {
        console.log("login success", resp);
        localStorage.setItem(
          "styli_fileUpload",
          JSON.stringify(resp.data.response)
        );
        setisLoggedIn(true);
        history.push("/bulkupload");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="eta-bulk-upload">
      <img className="eta-bulk-upload-child" alt="" src={loginBkground} />
      <div className="eta-bulk-upload-item" />
      <img className="group-icon" alt="" src="/group@2x.png" />
      <div className="welcome-to-sourcing">
        Welcome to Sourcing Progress Upload
      </div>
      <div className="please-use-your">
        <label className="errorLabel">{passwordError}</label>
        <label className="errorLabel">{emailError}</label>
        {/* Please use your Supplier Portal user name and password */}
      </div>
      <div className="user-name-parent">
        <div className="user-name">User name</div>
        <div className="rectangle-parent">
          <input
            value={email}
            // placeholder=""
            onChange={(ev) => setEmail(ev.target.value)}
            className="manjunath"
          />
        </div>
      </div>
      <div className="password-parent">
        <div className="user-name">Password</div>
        <div className="group-parent">
          <input
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(ev) => setPassword(ev.target.value)}
            className="manjunath"
          />
        </div>
        <img
          src={eyeIcon}
          alt="eye"
          className="eye-icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <div className="login-wrapper">
        <input
          className="login"
          type="button"
          onClick={onButtonClick}
          value={"Login"}
        />
      </div>
    </div>
  );
};

export default UserLogin;
